import React, { useContext } from "react";
import { ProductContext } from "../context/ProductContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Cart() {
  const history = useNavigate();
  const {
    state: { cart },
    dispatch,
  } = useContext(ProductContext);

  if (cart.length === 0) {
    return (
      <div className="h-dvh">
        {" "}
        <h1 className="text-amber-600 font-playfair text-3xl text-center">
          Cart is Empty
        </h1>
      </div>
    );
  }

  return (
    <motion.div
      className="min-h-dvh font-montserrat md:px-10"
      exit={{ opacity: 0, x: "-100vw" }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-amber-600 font-playfair text-3xl text-center">
        Cart
      </h1>
      <div className="my-10 px-5 md:px-20 space-y-3">
        {cart.map((item, index) => (
          <div
            key={index}
            className="text-amber-500 flex   space-x-2 justify-between md:items-center font-montserrat "
          >
            <div className="flex space-x-2 items-center">
              <img
                src={item.image}
                alt={item.name}
                className="h-24 w-24 object-cover object-center rounded-xl"
              />

              <div className="space-y-3">
                <h3 className="text-xl font-montserrat">{item.name}</h3>
                <h3 className="font-bold">
                  &#8358;{item.price.toLocaleString()}
                </h3>
              </div>
            </div>
            <div className="flex items-center justify-center space-y-3 md:justify-between w-1/2 flex-col md:flex-row">
              <h3> &#8358;{(item.quantity * item.price).toLocaleString()}</h3>
              <div className="space-x-3 flex">
                <button
                  className="bg-amber-600 text-white px-2 py-1 rounded-lg"
                  onClick={() => {
                    dispatch({
                      type: "CHANGE_QUANTITY",
                      payload: { ...item, quantity: item.quantity + 1 },
                    });
                  }}
                >
                  +
                </button>
                <h3 className="text-xl font-bold">{item.quantity}</h3>
                <button
                  className="bg-amber-600 text-white px-2 py-1 rounded-lg"
                  onClick={() => {
                    dispatch({
                      type: "CHANGE_QUANTITY",
                      payload: { ...item, quantity: item.quantity - 1 },
                    });
                  }}
                >
                  -
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between">
        <h1 className="text-amber-600 font-playfair text-3xl">
          Total: &#8358;
          {cart
            .reduce((acc, item) => acc + item.price * item.quantity, 0)
            .toLocaleString()}
        </h1>

        <button
          className="border border-amber-500 font-montserrat-alt text-amber-500 rounded-md py-2 px-4"
          onClick={() => history("/user-form")}
        >
          CHECK OUT
        </button>
      </div>
    </motion.div>
  );
}

export default Cart;
