import React from "react";
import { motion } from "framer-motion";
import { Parallax } from "react-parallax";
import { IoPeople, IoFastFoodSharp } from "react-icons/io5";
import { BiSolidDrink } from "react-icons/bi";

// import {  } from "react-icons/io5";

function About() {
  return (
    <div className="min-h-[500px]  bg-white">
      <Parallax
        bgImage="/assets/bg-image/IMG_3749.JPEG"
        strength={500}
        bgImageStyle={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "800px",
        }}
      >
        <div className="flex justify-center items-center w-full h-[500px] text-white bg-black/30">
          <h1
            className="font-bold font-playfair bg-custom-gradient bg-clip-text text-transparent text-5xl
          backdrop-filter backdrop-blur-md p-5
          "
          >
            About Yolo Lounge
          </h1>
        </div>
      </Parallax>

      <motion.p
        initial={{ opacity: 0, y: 20, height: 0 }}
        whileInView={{ opacity: 1, y: 0, height: "auto" }}
        transition={{ duration: 1.0, ease: "easeOut", delay: 0.6 }}
        viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
        className="p-5 my-2 font-montserrat-alt md:w-1/2 mx-auto overflow-hidden"
      >
        Welcome to YOLO Lounge & Restaurant – where great vibes, delicious
        bites, and unforgettable nights come together in perfect harmony! 🌟
      </motion.p>

      <motion.p
        initial={{ opacity: 0, y: 20, height: 0 }}
        whileInView={{ opacity: 1, y: 0, height: "auto" }}
        transition={{ duration: 1.0, ease: "easeOut", delay: 0.6 }}
        viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
        className="p-5 my-2 font-montserrat-alt md:w-1/2 mx-auto overflow-hidden"
      >
        At YOLO Lounge, we believe life is too short for boring experiences.
        That's why we’ve created a vibrant space where foodies, music lovers,
        and fun-seekers can indulge in the best that life has to offer. From our
        mouth-watering grills and small chops to our expertly crafted cocktails,
        every moment here is designed to be savored. 🍹🔥
      </motion.p>

      <motion.p
        initial={{ opacity: 0, y: 20, height: 0 }}
        whileInView={{ opacity: 1, y: 0, height: "auto" }}
        transition={{ duration: 1.0, ease: "easeOut", delay: 0.5 }}
        viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
        className="p-5 my-2 font-montserrat-alt md:w-1/2 mx-auto overflow-hidden"
      >
        Our lively atmosphere sets the stage for everything from laid-back
        brunches and cozy dinners to epic DJ nights and electrifying silent
        discos. Whether you're looking to chill with friends, enjoy a date
        night, or dance till dawn, YOLO Lounge is the place to be.
      </motion.p>
      <div>
        <h2 className="font-montserrat-alt font-bold text-2xl text-center my-5">
          Why Yolo?
        </h2>{" "}
        <motion.p
          initial={{ opacity: 0, y: 20, height: 0 }}
          whileInView={{ opacity: 1, y: 0, height: "auto" }}
          transition={{ duration: 1.0, ease: "easeOut", delay: 0.8 }}
          viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
          className="p-5 my-2 font-montserrat-alt md:w-1/2 mx-auto overflow-hidden"
        >
          Because You Only Live Once, and every experience at our lounge is
          meant to be a celebration of life, flavor, and good company. With a
          blend of great music, exceptional service, and a chic, welcoming
          ambiance, we bring you the ultimate dining and nightlife experience in
          the heart of town. 🎶✨
        </motion.p>
      </div>
      <div>
        <h2 className="font-montserrat-alt font-bold text-2xl text-center my-5">
          Our Mission
        </h2>
        <motion.p
          initial={{ opacity: 0, y: 20, height: 0 }}
          whileInView={{ opacity: 1, y: 0, height: "auto" }}
          transition={{ duration: 1.0, ease: "easeOut", delay: 0.9 }}
          viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
          className="p-5 my-2 font-montserrat-alt md:w-1/2 mx-auto overflow-hidden"
        >
          Come as you are, stay for the vibes, and leave with memories. Join us
          at YOLO Lounge & Restaurant, where every day is a chance to jaiyé
          proper! 🥂
        </motion.p>
      </div>
      <div className="min-h-96">
        <h2 className="font-montserrat-alt font-bold text-2xl text-center my-5">
          Our Numbers
        </h2>
        <div className="flex md:justify-between justify-center flex-wrap items-center gap-3 md:px-20">
          <motion.div
            initial={{ opacity: 0, y: "-20px" }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1.0,
              ease: "easeOut",
              delay: 0.7,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ visibility: 0.5, once: true, margin: "-70px" }}
            className="p-3 rounded-md border-2 flex flex-col justify-center items-center shadow-md text-center w-30 h-30 font-montserrat"
          >
            <IoPeople className="text-5xl" />
            <h3>100+</h3>
            <p>Happy Customers</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: "-20px" }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1.0,
              ease: "easeOut",
              delay: 0.8,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
            className="p-3 rounded-md border-2 flex flex-col justify-center items-center shadow-md text-center w-30 h-30 font-montserrat"
          >
            <BiSolidDrink className="text-5xl" />
            <h3>200+</h3>
            <p>Dishes and Deserts</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: "-20px" }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1.0,
              ease: "easeOut",
              delay: 0.9,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
            className="p-3 rounded-md border-2 flex flex-col justify-center items-center shadow-md text-center w-30 h-30 font-montserrat"
          >
            <IoFastFoodSharp className="text-5xl" />
            <h3>500+</h3>
            <p>Types of Drinks</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default About;
