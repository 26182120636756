import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { ProductContext } from "../context/ProductContext";
import { useNavigate } from "react-router-dom";

function CheckOutForm() {
  const initialState = {
    name: "",
    email: "",
    phone: "",
    address: "",
    state: "",
  };
  const [buyerInfo, setBuyerInfo] = useState(initialState);

  const {
    state:{cart},
    dispatch,
  } = useContext(ProductContext);

  const history = useNavigate()

  const handleChange = (e) => {
    let { name, value } = e.target;
    setBuyerInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "ADD_BUYER", payload: buyerInfo });

    history('/checkout')
  };
  if(cart.length <1){
    return (
      <div className="h-dvh">
        <h1 className="text-amber-600 font-playfair text-3xl text-center">
          Cart is Empty
        </h1>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: "200vw" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100vw" }}
      transition={{ duration: 0.5 }}
      className="h-dvh p-5 md:px-10 flex flex-col items-center  md:py-10"
    >
      <h2 className="text-2xl font-montecarlo font-extrabold text-white">Buyer's Info</h2>

      <p className="text-white my-5  italic font-serif">* Please do not refresh during this process and enter correct info</p>
      <form className=" px-2 py-5 md:py-14 border w-full border-amber-300 rounded-lg md:px-20 text-white font-montserrat md:w-2/3 space-y-3"
      onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1  md:grid-cols-3 text-center ">
          {" "}
          <label htmlFor="name" className="md:col-span-1 font-bold">Name:</label>{" "}
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={buyerInfo.name}
            required
            className="md:col-span-2 p-3 bg-transparent border-b outline-none border-amber-300 rounded-lg focus:border-amber-600"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 text-center ">
          {" "}
          <label htmlFor="phone" className="md:col-span-1 font-bold">Phone:</label>{" "}
          <input
            type="phone"
            name="phone"
            onChange={handleChange}
            value={buyerInfo.phone}
            required
            className="md:col-span-2 p-3 bg-transparent border-b outline-none border-amber-300 rounded-lg focus:border-amber-600"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 text-center ">
          {" "}
          <label htmlFor="email" className="md:col-span-1 font-bold">Email:</label>{" "}
          <input
            type="email"
            name="email"
            onChange={handleChange}
            value={buyerInfo.email}
            required
            className="md:col-span-2 p-3 bg-transparent border-b outline-none border-amber-300 rounded-lg focus:border-amber-600"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 text-center ">
          {" "}
          <label htmlFor="address" className="md:col-span-1 font-bold">Address:</label>{" "}
          <input
            type="text"
            name="address"
            onChange={handleChange}
            value={buyerInfo.address}
            required
            className="md:col-span-2 p-3 bg-transparent border-b outline-none border-amber-300 rounded-lg focus:border-amber-600"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 text-center ">
          {" "}
          <label htmlFor="state" className="md:col-span-1 font-bold">State:</label>{" "}
          <input
            type="text"
            name="state"
            onChange={handleChange}
            value={buyerInfo.state}
            required
            className="md:col-span-2 p-3 bg-transparent border-b outline-none border-amber-300 rounded-lg focus:border-amber-600"
          />
        </div>

        <button 
        type="submit"
        className="bg-amber-600 text-white px-4 py-2 rounded-lg hover:bg-amber-700 place-content-center col-span-3 w-full"
        >Submit</button>
      </form>
    </motion.div>
  );
}

export default CheckOutForm;
