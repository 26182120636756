export const buttunVariant = {
    hover: {
        scale: 1.1,
        transition: {
            repeat: 5,
            repeatType: "reverse",
            duration: 0.3
        },
        textShadow: "0px 0px 8px rgb(255, 255, 255)",
        boxShadow: "0px 0px 8px rgb(255, 255, 255)",
    }
}
export const containerVariant = {
    hidden: {
        opacity: 0,
        x: "100vw",
        transition: { staggerChildren: 0.1, when: "afterChildren" }
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { staggerChildren: 0.1, when: "beforeChildren" }
    }
}