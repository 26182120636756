import React from "react";


import { Parallax } from "react-parallax";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { buttunVariant } from "../../utils/animations";

function Section2() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex justify-center">
      <Parallax
        bgImage={"/assets/bg-image/IMG_3758.JPEG"}
        strength={500}
        bgImageStyle={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <div className="  flex flex-col md:flex-row px-10 py-10 md:px-20 items-center justify-center h-screen">
          <div
            style={{
              backgroundImage: `url("/assets/bg-image/IMG_3757.JPEG")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="flex flex-col items-center w-full justify-center rounded-t-lg h-[450px] md:rounded-l-lg md:w-1/2 "
          >
            <h2 className="text-7xl font-montecarlo font-bold text-white ">
              Our Story
            </h2>
          </div>
          <div className="grid place-content-center rounded-b-lg bg-slate-950 text-white md:w-1/2 h-[450px] font-montserrat p-7 md:rounded-r-lg space-y-10 ">
            <motion.h1
              initial={{ y: -50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{
                margin: "-100px",
              }}
              className="text-4xl font-montserrat-alt text-purple-500 text-center"
            >
              YOLO LOUNGE
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 1.2,
                ease: "easeOut",
                delay: 0.4,
                type: "spring",
                stiffness: 120,
              }}
            >
              Your culinary adventure starts here... Jaye proper. Welcome to a
              haven of relaxation and culinary delight. Yolo lounge and restaurant offers a unique blend of warm
              hospitality and exquisite flavors. Inspired by the vibrant culture
              and rich heritage of our nation, we invite you to embark on a
              journey of taste and tranquility
            </motion.p>
            <motion.button
              onClick={() => navigate("/about")}
              variants={buttunVariant}
              whileHover="hover"
              whileInView={{ scale: [0.8, 1.1, 1, 0.8, 1] }}
              className="bg-none rounded-full px-4 py-2 border border-white"
            >
              About Us
            </motion.button>
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default Section2;
