import React, { useContext } from 'react'
import { ProductContext } from '../../context/ProductContext'
import ItemCard from './ItemCard'

function Food() {

    const {state:{menuItems}} = useContext(ProductContext)

    const food = menuItems.filter(i=>i.type === "food")

  return (
    <div>
        <div className="flex flex-wrap gap-3 justify-center my-10 py-10">
        {food && food.map((item) => <ItemCard key={item._id} {...item} />)}
      </div>
    </div>
  )
}

export default Food