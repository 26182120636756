import React from "react";
import { motion } from "framer-motion";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { GiPartyHat } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

function Section3() {
  const navigate = useNavigate();

  return (
    <div className="bg-white flex flex-col md:flex-row items-center gap-5 px-5 md:px-10 py-10 justify-center min-h-screen">
      <motion.div
        initial={{ opacity: 0, x: "-200px" }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }}
        viewport={{ margin: "-100px" }}
        className="flex flex-col items-center group rounded-lg shadow-lg max-w-[400px] transition-all duration-500 ease-in-out transform overflow-hidden hover:scale-105 hover:shadow-xl"
      >
        <img
          src="/assets/pexels-orlovamaria-4915793.jpg"
          alt="menu"
          className="w-full h-48 object-cover object-center transition-all duration-700 ease-in group-hover:scale-110"
        />
        <div className="p-5 relative">
          <div className="rounded-full bg-white absolute -top-12 mx-auto left-0 right-0 w-24 h-24 grid place-content-center ">
            <MdOutlineRestaurantMenu className="text-5xl text-gray-500 transition-all duration-300 ease-in group-hover:text-purple-500" />
          </div>
          <h2 className=" font-montserrat-alt font-bold text-xl transition-all duration-500 group-hover:text-purple-500 mt-10">
            Check Out Our Delicacies
          </h2>
          <p className="font-montserrat text-gray-500 group-hover:text-gray-700">
            Experience the vibrant flavors of intercontinental cuisines at our
            restaurant. From our signature jollof rice to our mouthwatering
            suya, every dish is a celebration of authentic experience...
          </p>
        </div>
        <button
          onClick={() => navigate("/menu")}
          className="border border-purple-500 text-purple-500 font-montserrat-alt font-bold px-5 py-2 rounded-lg my-5 transition-all duration-300 ease-in-out hover:bg-purple-500 hover:text-white group-hover:shadow-lg"
        >
          See Menu &#8658;
        </button>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "-200px" }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.5 }}
        viewport={{ margin: "-100px" }}
        className="flex flex-col items-center max-w-[400px] group rounded-lg shadow-lg  transition-all duration-500 ease-in-out transform overflow-hidden hover:scale-105 hover:shadow-xl"
      >
        <img
          src="/assets/bg-image/IMG_3750.JPEG"
          alt="Resevation"
          className="w-full h-48 object-cover object-center transition-all duration-700 ease-in group-hover:scale-110"
        />
        <div className="p-5 relative">
          <div className="rounded-full bg-white absolute -top-12 mx-auto left-0 right-0 w-24 h-24 grid place-content-center ">
            <HiOutlineClipboardDocumentCheck className="text-5xl text-gray-500 transition-all duration-300 ease-in group-hover:text-purple-500" />
          </div>
          <h2 className=" font-montserrat-alt font-bold text-xl transition-all duration-500 group-hover:text-purple-500 mt-10">
            Make Reservation
          </h2>
          <p className="font-montserrat text-gray-500 group-hover:text-gray-700">
            Enjoy a seamless dining experience by reserving your table in
            advance. Whether it's a date night, family gathering, or a solo
            treat, we’re here to make sure your time with us is exceptional...
          </p>
        </div>
        <button
          onClick={() => navigate("/reservation")}
          className="border border-purple-500 text-purple-500 font-montserrat-alt font-bold px-5 py-2 rounded-lg my-5 transition-all duration-300 ease-in-out hover:bg-purple-500 hover:text-white group-hover:shadow-lg"
        >
          Make Reservation &#8658;
        </button>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "-200px" }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
        viewport={{ margin: "-100px" }}
        className="flex flex-col items-center group rounded-lg shadow-lg  max-w-[400px] transition-all duration-500 ease-in-out transform overflow-hidden hover:scale-105 hover:shadow-xl"
      >
        <img
          src="/assets/Grooving/DSC_8815.jpg"
          alt="Events"
          className="w-full h-48 object-cover object-center transition-all duration-700 ease-in group-hover:scale-110"
        />
        <div className="p-5 relative">
          <div className="rounded-full bg-white absolute -top-12 mx-auto left-0 right-0 w-24 h-24 grid place-content-center ">
            <GiPartyHat className="text-5xl text-gray-500 transition-all duration-300 ease-in group-hover:text-purple-500" />
          </div>
          <h2 className=" font-montserrat-alt font-bold text-xl transition-all duration-500 group-hover:text-purple-500 mt-10">
            See Coming Events
          </h2>
          <p className="font-montserrat text-gray-500 group-hover:text-gray-700">
            Stay tuned for an array of exciting events happening soon at our
            venue. From live music nights to exclusive dining experiences,
            there's always something special in store...
          </p>
        </div>
        <button 
        onClick={()=>navigate('/events')}
        className="border border-purple-500 text-purple-500 font-montserrat-alt font-bold px-5 py-2 rounded-lg my-5 transition-all duration-300 ease-in-out hover:bg-purple-500 hover:text-white group-hover:shadow-lg">
          View Events &#8658;
        </button>
      </motion.div>
    </div>
  );
}

export default Section3;
