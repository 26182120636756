import React from 'react'
import { motion } from 'framer-motion'

function Career() {
  return (
    <div className='min-h-dvh bg-slate-50 font-montserrat'>
        <div
        style={{
            backgroundImage: `url('/assets/pexels-olly-3801426.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '30px'
        }}
        >
            <motion.h1
            initial={{ x: -250 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.5, type: 'spring', stiffness: 120 }}
            className='text-4xl font-montserrat-alt font-extrabold text-amber-500 text-center'>Join Our Dynamic Team</motion.h1>

        </div>

        <h2 className='text-center my-5 font-bold'>No opening now</h2>
    </div>
  )
}

export default Career