import React, { useContext } from 'react'
import { Parallax } from 'react-parallax'
import { ProductContext } from '../context/ProductContext'
import ItemCard from './Menu/ItemCard'

function Product() {
    const {state:{products}} = useContext(ProductContext)

    console.log(products.products)

    const product = products.products

  return (
    <div className='bg-slate-50 min-h-screen'>
        <Parallax
        bgImage="/assets/Grooving/DSC_8661.jpg"
        strength={500}
        bgImageStyle={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="flex justify-center items-center w-full h-[300px] text-white bg-black/30">
          <h1
            className="font-bold font-playfair bg-custom-gradient bg-clip-text text-transparent text-5xl
          backdrop-filter backdrop-blur-md p-5
          "
          >
            Our Products
          </h1>
        </div>
      </Parallax>
      <div className='flex gap-3 flex-wrap py-10 justify-center items-center'>
        {product&&product.map((item,index)=><ItemCard key={index} {...item}/>
        )}
      </div>
    </div>
  )
}

export default Product