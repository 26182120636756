import React, { useContext, useState } from "react";
import { ProductContext } from "../../context/ProductContext";
import ItemCard from "./ItemCard";
import ReactPaginate from "react-paginate";

function Drink() {
  const {
    state: { menuItems },
  } = useContext(ProductContext);

  const drink = menuItems.filter((i) => i.type === "drink");
  console.log("drink", menuItems);

  const itemsPerPage = 6;

  // Track current page
  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the total number of pages
  const pageCount = Math.ceil(drink.length / itemsPerPage);

  // Determine which drink to display based on the current page
  const currentItems = drink.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Handle page change
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <div className="bg-white">
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination flex justify-center mt-4 space-x-2"}
        pageClassName={"page-item"}
        pageLinkClassName={"px-3 py-1 border rounded-md overflow-hidden"}
        previousLinkClassName={"px-3 py-1 border rounded-full"}
        nextLinkClassName={"px-3 py-1 border rounded-full"}
        activeClassName={"bg-custom-gradient text-white "}
        disabledClassName={"opacity-50 cursor-not-allowed"}
      />

      <div className="flex flex-wrap justify-center gap-2 items-center m-5 py-10 ">
        {currentItems.map((item) => (
          <ItemCard key={item._id} {...item} />
        ))}
      </div>
    </div>
  );
}

export default Drink;
