import React, {useState} from "react";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdPhone } from "react-icons/md";
import { IoLogoInstagram } from "react-icons/io5";
import { motion } from "framer-motion";
import { containerVariant } from "../utils/animations";
import { Parallax } from "react-parallax";
import emailjs from "emailjs-com";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEnquiryEmail = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // You can also use toLocaleString()

      // Include the formatted date in the email data
      const emailData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      };

      const response = await emailjs.send(
        "service_3ixqpek",
        "template_etvvist",
        emailData,
        "TE_pd5r_6D6XKVVXp"
      );

      console.log("Reservation email sent:", response.status, response.text);

      setLoading(false);
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      setLoading(false)
      setError("Failed to send reservation email");
      console.error(error);
    }
  };
  return (
    <>
        <Parallax
        bgImage="/assets/Grooving/DSC_8661.jpg"
        strength={500}
        bgImageStyle={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="flex justify-center items-center w-full h-[300px] text-white bg-black/30">
          <h1
            className="font-bold font-playfair bg-custom-gradient bg-clip-text text-transparent text-5xl
          backdrop-filter backdrop-blur-md p-5
          "
          >
            Reach Out to Us
          </h1>
        </div>
      </Parallax>

    <div className=" text-white  mx-2 md:mx-10">
      <div className="flex justify-center space-x-5 font-mono flex-wrap  items-center mt-10 ">
        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", 
              
            },
          }}
          href="https://wa.me/2348066228063?text=I%20want%20to%20make%20a%20reservation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="text-3xl hover:text-green-500"/>
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="mailto:Yololounge0@gmail.com?subject=Reservation%20Line&body=This%20is%20the%20body%20of%20the%20email"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HiOutlineMail className="text-3xl hover:text-blue-500"/>
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="tel:+2348066228063"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-1"
        >
          <MdPhone className="text-3xl hover:text-slate-500"/>
          <span>+2348066228063</span>
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="tel:+2349127256466"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-1"
        >
          <MdPhone className="text-3xl hover:text-slate-500"/>
          <span>+2349127256466</span>
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="https://www.instagram.com/Yolo_lounge_restaurant"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center"
        >
          <IoLogoInstagram className="text-3xl hover:text-rose-500"/>
          
        </motion.a>
      </div>
      <motion.div
        variants={containerVariant}
        initial="hidden"
        animate="visible"
        className="flex flex-col items-center justify-center h-screen"
      >
        <form
          onSubmit={sendEnquiryEmail}
          className="shadow-xl w-full md:w-1/2 rounded-xl p-5 bg-custom-gradient"
        >
          <h2 className="text-3xl font-bold font-montecarlo text-center mb-5 text-neutral-100">
            Contact Us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Full Name"
              className="border border-purple-500 rounded-md p-2 outline-none "
            />
            <input
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              placeholder="Email"
              className="border border-purple-500 rounded-md p-2 outline-none  "
            />
            <input
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              type="phone"
              placeholder="Phone Number"
              className="border border-purple-500 rounded-md p-2 outline-none md:col-span-2"
            />
            
            
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              id="message"
              cols="30"
              rows="10"
              className="border border-purple-500 rounded-md p-2 text-black outline-none md:col-span-2"
              placeholder="Please Enter your message"
            ></textarea>

            <motion.button
              type="submit"
              className="border text-white p-2 rounded-md md:col-span-2"
              whileHover={{ scale: 1.05, backgroundColor: "#6B46C1" }}
              whileTap={{ scale: 0.9 }}
              transition={{ duration: 0.2 }}
              disabled={loading}
            >
              {loading? "Sending..." : "Reserve"}
            </motion.button>
          </div>
          {error && (<div className="p-4 text-red-500 border-2 rounded-lg border-red-500 my-3">{error}</div>)}
        </form>
      </motion.div>

    </div>
    </>
  );
}

export default Contact;
