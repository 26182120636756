import React, { useContext } from "react";
import { FaCartShopping } from "react-icons/fa6";
import { ProductContext } from "../../context/ProductContext";

function ItemCard(items) {
  const { name, price, description, image, category } = items;

  const {
    state: { cart },
    dispatch,
  } = useContext(ProductContext);

  console.log(cart);

  return (
    <>
      <div className="rounded-lg group flex flex-col shadow-lg transition-all ease-linear duration-200 w-[150px] md:w-[250px] overflow-hidden hover:shadow-xl ">
        <img
          src={image}
          alt={name}
          className="w-full h-28  md:h-48 object-cover  transition-all  ease-linear duration-300 group-hover:scale-105"
        />

        <div className="p-5 font-montserrat">
          <h2 className="font-bold transition-all text-xs md:text-xl font-montserrat-alt ease-linear duration-200 group-hover:text-purple-900">
            {name}
          </h2>
          <p className="text-gray-600 text-sm hidden md:block">
            {category.map((i, index) => (
              <li key={index}>{i.toUpperCase()}</li>
            ))}
          </p>
          <p className="text-gray-800 font-bold">
            &#8358;{price.toLocaleString()}
          </p>

          {cart.some((c) => c._id === items._id) ? (
            <button
              className=" bg-purple-600 border-white w-full border text-sm text-white font-montserrat-alt py-2 px-4 rounded-lg mt-2 transition-all duration-150 ease-linear group-hover:text-purple-600 group-hover:bg-transparent group-hover:border group-hover:border-purple-600"
              onClick={() => {
                dispatch({ type: "REMOVE_FROM_CART", payload: items._id });
                // localStorage.setItem("cart", JSON.stringify(cart.filter((c)=>c._id !== items._id)))
              }}
            >
              Remove
            </button>
          ) : (
            <button
              className=" bg-purple-600 border-white w-full border text-sm text-white font-montserrat-alt py-2 px-4 rounded-lg mt-2 transition-all duration-150 ease-linear group-hover:text-purple-600 group-hover:bg-transparent group-hover:border group-hover:border-purple-600"
              onClick={() => {
                dispatch({ type: "ADD_TO_CART", payload: items });
                // localStorage.setItem("cart", JSON.stringify([...cart, items]))
              }}
            >
              <FaCartShopping className="inline-block text-xl" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ItemCard;
