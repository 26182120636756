import React from "react";
import { Parallax } from "react-parallax";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { buttunVariant } from "../utils/animations";
import Section2 from "./DashBoard/Section2";
import Section3 from "./DashBoard/Section3";
import WorkForm from "./DashBoard/WorkForm";
import Gallery from "./DashBoard/Gallery";

function Dashboard() {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <Parallax
          bgImage={"/assets/bg-image/IMG_3756.JPEG"}
          strength={500}
          bgImageStyle={{
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            height: "800px",
          }}
        >
          <div
            style={{ height: "700px" }}
            className="flex items-center justify-center font-montserrat flex-col text-white bg-black/50 space-y-20 "
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.0, ease: "easeOut", delay: 0.2 }}
              className="text-center flex flex-col items-center justify-center space-y-10"
            >
              <h1 className="bg-custom-gradient bg-clip-text text-transparent text-5xl font-extrabold font-montserrat-alt">
                YOLO LOUNGE & RESTAURANT
              </h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.0, ease: "easeOut", delay: 0.5 }}
                viewport={{ visibility: 0.5, once: true, margin: "-200px" }}
                className="font-playfair text-2xl text-wrap w-2/3"
              >
                YOLO Lounge & Restaurant ✨ Experience vibrant flavors,
                handcrafted cocktails, and stylish ambiance. Unwind, indulge,
                and make every moment count. You Only Live Once—make it
                delicious! 🍽️🍹 #YOLOExperience"
              </motion.p>
            </motion.div>

            <div className="flex flex-col items-center justify-center space-y-10">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 1.2,
                  ease: "easeOut",
                  delay: 1.0,
                  type: "spring",
                  stiffness: 120,
                }}
              >
                <motion.button
                  onClick={() => navigate("/menu/food")}
                  variants={buttunVariant}
                  whileHover="hover"
                  className="bg-none rounded-full px-4 py-2 border border-white"
                >
                  Explore Our Menu
                </motion.button>
              </motion.div>
            </div>
          </div>
        </Parallax>
      </div>
      <div className="min-h-dvh ">
        <Section2 />
        <Section3 />
        <Gallery />
        <WorkForm />
      </div>
    </div>
  );
}

export default Dashboard;
