import React from 'react'
import { GiClockwork } from "react-icons/gi"
import {motion} from 'framer-motion'

function WorkForm() {
  return (
    <div className='min-h-96 flex items-center justify-center px-7'>
        <motion.div 
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: 1, y: 0}}
        transition={{duration: 1.0, ease: "easeOut", delay: 0.2}}
        className='p-5 border border-rose-500 rounded-lg bg-custom-gradient w-full md:w-1/2'>
            <h2 className='font-montserrat underline font-bold text-2xl text-white text-center mb-3'>
                Opening Hours
            </h2>
            <div className='text-center space-y-5 w-full'>
            <p className='flex space-x-3 font-bold text-slate-100'>
               <GiClockwork className='text-2xl mr-4'/> Monday - Thursday: 10am - 11:30pm
            </p>
            <p className='flex space-x-3 font-bold text-slate-100'>
               <GiClockwork className='text-2xl mr-4'/> Friday - Sunday: 10am - till dawn
            </p>
            
            </div>
        </motion.div>
    </div>
  )
}

export default WorkForm