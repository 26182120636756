import React from "react";
import { Link } from "react-router-dom";

function Page404() {
  return (
    <div className="min-h-dvh  flex items-center  w-screen flex-col justify-around">
      <p className="font-bold text-2xl italic font-playfair text-white">
        CAN'T FIND PAGE!!!
      </p>
      <img src="/assets/not-found.webp" alt="page not found" className="h-96" />
      <p className="text-white text-xl font-montserrat">
        <Link to="/">Go back home</Link>
      </p>
    </div>
  );
}

export default Page404;
