import React, { useContext } from 'react'
import { Parallax } from 'react-parallax'
import { motion } from 'framer-motion'
import { ProductContext } from '../context/ProductContext'

function Event() {

const {state:{events}} = useContext(ProductContext)


  return (
    <div className='min-h-screen bg-white '>
        <Parallax
        bgImage="/assets/pexels-mediocrememories-2240763.jpg"
        strength={500}
        bgImageStyle={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="flex justify-center items-center w-full h-[300px] text-white bg-black/30">
          <h1
            className="font-bold font-playfair bg-custom-gradient bg-clip-text text-transparent text-5xl
          backdrop-filter backdrop-blur-md p-5
          "
          >
            Events
          </h1>
        </div>
      </Parallax>
        <div className='py-20 flex gap-4 flex-wrap items-center justify-center px-10'>
          {events&&events.map((item)=>(
            <motion.div
            initial={{opacity:0, y:20}}
            animate={{opacity:1, y:0}}
            transition={{duration:1.0, ease:'easeOut', delay:0.2}}
            
            className='bg-cover bg-center group w-[350px] h-[350px] md:h-[400px] rounded-lg shadow-lg  transition-all duration-300 ease-in-out overflow-hidden relative'
            >
              <div
              style={{backgroundImage: `url(${item.image})`}}
              className='bg-cover bg-center w-full h-full group-hover:shadow-xl group-hover:scale-105 transition-transform duration-300 ease-in-out absolute z-0'
              >

            <div className='bg-black/50 p-5 h-full flex flex-col justify-end absolute inset-0 z-10'>
              <h1 className='text-white text-2xl font-bold font-montserrat-alt'>{item.title}</h1>
              <p className='text-white text-sm'>{new Date(item.date).toDateString()}</p>
              <p className='text-white'>{item.time}</p>
              <p className='text-neutral-100 font-bold'>Ticket: &#8358;{item.ticket.toLocaleString()}</p>
            </div></div>
            </motion.div>
          ))}
        </div>
    </div>
  )
}

export default Event